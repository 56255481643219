p {
  color: $black;
}

a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
