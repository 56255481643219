@import "~bootstrap/scss/mixins";
$newgray: #5b5b5b;

.card {
  border-radius: 15px;
  overflow: hidden;
  border: 1px solid $newgray;

  &--shadow {
    -webkit-box-shadow: 0px 4px 18px -2px rgba(82, 82, 82, 0.55);
    -moz-box-shadow: 0px 4px 18px -2px rgba(82, 82, 82, 0.55);
    box-shadow: 0px 4px 18px -2px rgba(82, 82, 82, 0.55);
  }

  &-body {
    padding: 0rem;
  }
}

@include media-breakpoint-up(sm) {
  .card {
    &-body {
      padding: 0rem;
    }
  }
}
