//BUTTON

.btn-secondary {
  /*  @include button-outline-variant($primary,darken($primary,15%),transparent,$primary);
  border-width: 1px;
  border-color:lighten($primary,15%);*/
  color: $white;
  border: none;
  outline: 0;
}

.btn-primary {
  color: $white;
}

.btn-warning {
  color: $white;

  &:hover {
    color: $white;
  }
}

.btn-outline-dark:focus {
  box-shadow: none;
}

.btn-lg {
  font-weight: bold;
}

.btn-light {
  color: #4c4c4c;
  &:hover {
    color: #4c4c4c;
  }
}
.btn-white {
  color: black;
  background-color: $white;
  &:hover {
    color: black;
    background-color: $white;
  }
  &:active {
    background-color: $white !important;
    color: black !important;
  }
  //border:1px solid black;
}

//
// Button Sizes
//
$btn-lg-font-size: 36px;
$btn-lg-padding-y: 0.8rem;

.btn-lg {
  @include button-size(
    $btn-lg-padding-y,
    $btn-padding-x-lg,
    $btn-lg-font-size,
    $btn-line-height-lg,
    $btn-border-radius-lg
  );
}

//
// Button Circle
//
.btn-circle {
  border-radius: 50%;
  line-height: 1.33;
  padding: 0.5rem;
  min-width: 2.5rem;
  width: 3.2rem;
  height: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-circle-sm {
  border-radius: 50%;
  line-height: 1.33;
  padding: 0.3rem;
  min-width: 2.5rem;
  width: 2.5rem;
  height: 2.5rem;
}
