//  LIST GROUP
.list-group-item {
  color: $black;
  font-weight: bold;
  font-size: $font-size-base;
  margin-bottom: 0;
  border: none;
  border-bottom: 2px solid $gray-800;
  padding-left: 0.9375rem;
  padding-top: 2.5rem;
  padding-bottom: 1.75rem;
  cursor: pointer;
  transition: 0.2s ease;
  .icon {
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="32" height="32" viewBox="0 0 24 24"><path fill="'+$black+'" d="M2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12M10,17L15,12L10,7V17Z" /></svg>')
      no-repeat;
    width: 30%;
    height: 30%;
    background-size: contain;
    transition: 0.2s ease;
  }
}

.list-group-item.selected,
.list-group-item:hover {
  color: $primary;
  .icon {
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="32" height="32" viewBox="0 0 24 24"><path fill="'+$primary+'" d="M2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12M10,17L15,12L10,7V17Z" /></svg>')
      no-repeat;
    width: 30%;
    height: 30%;
    background-size: contain;
  }
}
