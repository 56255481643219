#react-app {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

body {
  background: #fff;
}

.lightblue-container {
  background-color: #f1f1f1;
  width: 100%;
}

pre {
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word;
}
