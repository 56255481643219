.default-container {
  padding-top: 3.75rem;
}

.modal {
  -webkit-overflow-scrolling: touch;
}

html {
  @include media-breakpoint-up(xs) {
    font-size: 85% !important;
  }
  @include media-breakpoint-up(sm) {
    font-size: 85% !important;
  }
  @include media-breakpoint-up(md) {
    font-size: 90% !important;
  }
  @include media-breakpoint-up(lg) {
    font-size: 95% !important;
  }

  @include media-breakpoint-up(xl) {
    font-size: 100% !important;
  }
}
